.errorContainer{
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr 7vh;
  grid-template-columns: 1fr;
  color: #fff;
  font-size: 12px;
}
.errorMsg {
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
}
.homeBtn {
  display: flex;
  place-content: center;
  place-items: center;
  font-size: 16px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius:10px;
  border: 1px solid #7b7b7b;
  cursor: pointer;
}