@import "~font-awesome/css/font-awesome.css";

/* GENERAL */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
@font-face {
  font-family: "bold";
  src: local("bold"), url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "semiBold";
  src: local("semiBold"),
    url(./assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "normal";
  src: local("normal"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "light";
  src: local("light"), url(./assets/fonts/OpenSans-Light.ttf) format("truetype");
}
html,
body {
  height: 100vh !important;
  width: 100vw !important;
  background-color: #041027 !important;
  font-size: 100%;
}

body {
  font-family: normal, sans-serif !important;
}

.centered {
  /* margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; */
}
.my-labels {
  width: 150px !important;
  text-align: center;
}
.leaflet-tooltip.my-labels {
  position: absolute;
  font-size: 0px;
  background: none;
  border: none;
  color: black;
  box-shadow: none;
  outline: none;
  transform: inherit;
  font-weight: bolder !important;
  word-wrap: break-word !important;
  white-space: unset !important;
  overflow-wrap: break-word !important;
  /* // word-break: break-word !important; */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  line-height: 0.7 !important;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: rgb(24, 36, 56, 0.8);
}
.modal {
  display: flex;
  place-content: center;
  place-items: center;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  /* background: rgb(24, 36, 56, 0.8) !important; */
  background: rgb(3, 13, 33, 0.8) !important;
}
.test {
  display: grid;
  /* background: rgb(24, 36, 56, 0.8) !important; */
  background: rgb(3, 13, 33, 0.8) !important;
  z-index: 99999;
}
.test > .modal-dialog {
  bottom: 150px !important;
  position: absolute !important;
  width: 95vw !important;
  padding: 0px 11px;
}
.modal-content {
  background-color: #1f2551 !important;
  color: #fff !important;
  border-radius: 20px !important;
  border: 1px solid rgba(255, 255, 255, 0.48);
  width: 90vw;
}

.modal-header {
  display: block !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  padding: 1rem 1rem !important;
  border-bottom: 1px solid #3a475e !important;
  border-top-left-radius: calc(0.3rem - 1px) !important;
  border-top-right-radius: calc(0.3rem - 1px) !important;
}
.modal-body {
  padding-bottom: 0px !important;
  font-size: calc(14px + (21 - 14) * ((100vw - 300px) / (1600 - 300)));
  padding: 15px !important;
  text-align: center;
}
.modal-body-container {
  bottom: 2px;
  margin-bottom: 0px !important;
}
.header-main-title {
  font-size: 14px;
}
.header-main-title {
  font-size: 14px;
}
.spinner > .modal-dialog {
  /* bottom: 150px !important;
  position: absolute !important;
  width: 95vw !important;
  padding: 0px 11px; */
  padding: 0px !important;
  margin: 0px !important;
}
.spinner > .modal-dialog > .modal-content {
  width: auto;
  margin: 0px auto;
  background-color: rgba(23, 38, 65, 0.4) !important;
  border: none !important;
}
.spinner-color {
  color: #1f2559 !important;
}
.dark-spinner-color {
  color: #1f2559 !important;
}

/**/
.loaderContainer {
  display: flex;
  place-content: center;
  place-items: center;
  /* transform: translate(-50%, -50%); */
  width: 100vw;
  height: 100vh;
}
.dotContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 75px;
}
.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.dot1 {
  animation-name: flow;
  animation-duration: 0.4s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  /* background-color: #fab16d; */
  background-color: #ffffff;
}
.dot2 {
  animation: flow 0.4s ease 0.2s infinite alternate;
  /* background-color: #86bce4; */
  background-color: #ffffff;
}
.dot3 {
  animation: flow 0.4s ease 0.4s infinite alternate;
  /* background-color: #f36c20; */
  background-color: #ffffff;
}
@keyframes flow {
  from {
    opacity: 1;
    transform: scale(1.2);
  }
  to {
    opacity: 0.2;
    transform: scale(0.75);
  }
}
.wfh {
  background-color: #f36c20;
}
.wfo {
  background-color: #1f2559;
}
.outOfOffice {
  background-color: #fab16d;
}
.leave {
  background-color: #86bce3;
}
.gray-text {
  color: #aaacb2;
}
/*another-popop style*/
.another-popup .leaflet-popup-content-wrapper,
.colleague-popup .leaflet-popup-content-wrapper {
  background: rgba(255, 255, 255, 0.99);
  color: #eee;
  font-size: 12px;
  line-height: 24px;
  border-radius: 0px;
  width: 190px;
  min-height: 30px;
  /* min-height:min-content; */
  top: 35px;
  left: -109px;
  position: absolute;
  border-radius: 10px;
}
.colleague-popup .leaflet-popup-content-wrapper {
  width: 205px !important;
  min-height: 30px;
}

.another-popup .leaflet-popup-content-wrapper:before,
.colleague-popup .leaflet-popup-content-wrapper:before {
  /* content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 25px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 33px solid rgba(23, 38, 65, 0.95);
  left: 36%;
  top: -21px;
  transform: rotate(370deg); */
  content: "";
  position: absolute;
  left: 36%;
  /* top: -13px; */
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: rgba(64, 78, 103, 1) transparent;
  display: block;
  width: 0;
  z-index: 0;
  transform: rotate(335deg);
  border-top: 12px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 20px solid rgba(255, 255, 255, 0.99);

  height: 10px;
  top: -2px;
}

.leaflet-container a.leaflet-popup-close-button {
  display: none;
  /*  position: absolute;
  top: 38px;
  left: 72px;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent; */
}

.another-popup .leaflet-popup-content-wrapper a,
.colleague-popup .leaflet-popup-content-wrapper a {
  color: rgba(200, 200, 200, 0.1);
}
.another-popup .leaflet-popup-tip-container,
.colleague-popup .leaflet-popup-tip-container {
  width: 50px;
  height: 15px;
}
.another-popup .leaflet-popup-tip,
.colleague-popup .leaflet-popup-tip {
  background: transparent;
  border: none;
  box-shadow: none;
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
  font-size: 14px;
  /* font-weight: bold; */
  margin: 0px;
  /* text-align: center; */
  /* width: auto !important; */
  min-width: 68px !important;
  width: 100% !important;
  height: 100% !important;
  /*position: absolute;*/
}

.colleagueContainer {
  display: grid;
  padding: 5px 0px;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(45px, auto) 1fr;
}
.ColleagueImgContainer .colleagueName {
  border-radius: 100%;
  border: 1px solid #1f2559;
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  position: absolute;
  color: #fff;
  background-color: #1f2559;
  font-weight: 500;
}
.ColleagueImgContainerNew .colleagueName {
  border-radius: 100%;
  border: 1px solid #1f2559;
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* top: 45px; */
  position: relative;
  color: #fff;
  background-color: #1f2559;
  font-weight: 500;
}
.userSeatContainerNew {
  display: grid;
  padding: 10px 5px 0px 15px;
  grid-template-rows:
    1fr
    /*1fr*/;
  grid-template-columns: 1fr;
  width: 100%;
  /*height: 100%;*/
  /* border: 1px solid red; */
  text-align: left;
  overflow: hidden;
  /* margin-bottom: -5px; */
  max-height: 195px !important;
  overflow-y: auto;
}
.bottom10 {
  margin-bottom: 10px;
}
.userSeatAllocationTime {
  /* color: #031126;
	font-weight: 500;
	font-size: 13px; */
  /* margin-top: 5px; */
}
.userAllocationName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #031126;
  font-weight: normal;
  margin-top: 8px;
  margin-left: 43px;
}
.hrLineAllocation {
  /*backgroundColor: '#031126';*/
  /*border-color: #031126;*/
  border-top-color: #d8d5d5;
  height: 0.1;
  /*border-width: thin;*/
  margin-left: -16px;
  margin-right: -16px;
  margin-top: 5px;
  margin-bottom: 8px;
}
.allocationMain {
  /*height: 60px;*/
  width: 100%;
  overflow-y: auto;
}
.parentView {
  height: 36px;
  display: inline-flex;
  margin-bottom: 5px;
}
.allocationInfo {
  max-height: 60px;
  color: #031126;
  font-weight: 450;
  font-size: 12px;
  height: 20px;
}
.allocationName {
  max-height: 60px;
  color: #393a3b;
  /* font-weight: 500; */
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box;
	-webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
  color: #031126;
  font-weight: normal;
  margin-top: 8px;
  margin-left: 7px;
}
.colleagueContainer .userDeskContainer {
  grid-template: auto auto / 1fr !important;
}
.userSeatContainer {
  display: grid;
  padding: 5px 5px;
  grid-template-rows: 1fr /*1fr*/;
  grid-template-columns: 1fr;
  width: 100%;
  /*height: 100%;*/
  /* border: 1px solid red; */
  text-align: left;
  overflow: hidden;
}

.userSeatName {
  overflow: show;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #3e4043;
  font-weight: 500;
  min-height: 20px;
  max-height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.userSeatNameBooked {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #031126;
  font-weight: normal;
  margin-top: 5px;
}
.hrLine {
  /*backgroundColor: '#031126';*/
  /*border-color: #031126;*/
  border-top-color: #bab6b6;
  height: 0.1;
  /*border-width: thin;*/
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.seatDisplayName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #031126;
  font-weight: 500;
}
.cabinInfo {
  display: grid;
  /*grid-template: 1fr 1fr / 1fr;*/
  color: #031126;
  /*max-height: 50%*/
}
.amenitymain {
  /*height: 60px;*/
  width: 100%;
  overflow-y: auto;
}
.amenityinfo {
  max-height: 60px;
  /*width: 90%;
	display: block; 
  display: -webkit-box;
  height: 5.2em; 
  //line-height: 1.3em;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;*/
}
.userDeskContainer {
  /*border-bottom: 1px solid #5d697e;*/
  display: grid;
  /* //max-height: 60px; */
  display: flex;
  flex-direction: column;
}
.capacity {
  color: #101f54;
}
.userBookingTiming {
  color: #031126;
  font-weight: 500;
  /*font-size: 13px;*/
}
.leaflet-tooltip.colleagueInfoContainer {
  opacity: 0.9;
  display: flex !important;
  place-content: center !important;
  place-items: center !important;
  position: absolute !important;
  width: auto !important;
  box-shadow: none !important;
  background: none !important;
  border: none !important;
  border-radius: 0px;
  padding: 0px;
}
.colleagueInfo {
  display: flex;
  place-content: center;
  place-items: center;
  position: absolute;
}
.colleagueInfo .colleagueInfoImg {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.colleagueInfoName {
  background-color: #1f2559;
  color: #fff;
  font-weight: bold;
  border-radius: 100%;
}
.alertClass {
  position: absolute !important;
  z-index: 99999 !important;
  margin: 3px !important;
  width: 97vw;
}
.modal-dialog .modal-dialog-centered {
  margin: 0px !important;
  padding: 0px !important;
}
.availability {
  display: grid !important;
  /* background: rgb(24, 36, 56, 0.8) !important; */
  background: rgb(3, 13, 33, 0.8) !important;
  z-index: 99999;
  place-items: center;
  place-content: center;
}
.availability > .modal-dialog > .modal-content > .modal-body {
  padding: 0px !important;
}
